import { BusinessSignupData } from '../types/BusinessSignupData'
import { SetupBusinessInformationRequest } from '../models/ApiModels'
import { DiscoveryBasicsInformation } from '../models/models'

export default class Mappers {
  MapToSetupBusinessInformationRequest = (
    businessInfo: BusinessSignupData
  ): SetupBusinessInformationRequest =>
    ({
      ConfirmedIndependent: true,
      BusinessName: businessInfo.aboutBusiness?.name,
      TradingType: businessInfo.businessType,
      'TradingAddress.AddressLine1': businessInfo.homeAddress?.address1,
      'TradingAddress.AddressLine2': businessInfo.homeAddress?.address2,
      'TradingAddress.City': businessInfo.homeAddress?.city,
      'TradingAddress.County': businessInfo.homeAddress?.county,
      'TradingAddress.Postcode': businessInfo.homeAddress?.postcode,
      IsARegisteredBusiness:
        businessInfo.aboutBusiness?.businessType === 'Company',
      IncorporationDate: '',
      RegistrationNumber: businessInfo.aboutBusiness?.businessNumber,
      LegalName: businessInfo.aboutBusiness?.name,
      VatNumber: '',
      'RegisteredAddress.AddressLine1': businessInfo.businessAddress?.address1,
      'RegisteredAddress.AddressLine2': businessInfo.businessAddress?.address2,
      'RegisteredAddress.City': businessInfo.businessAddress?.city,
      'RegisteredAddress.County': businessInfo.businessAddress?.county,
      'RegisteredAddress.Postcode': businessInfo.businessAddress?.postcode,
      BusinessLocationLatitude: '',
      BusinessLocationLongitude: '',
      ChosenSubscriptionPlanType: businessInfo.subscriptionPlanType,
    } as SetupBusinessInformationRequest)

  MapToBasics = (
    businessInfo: BusinessSignupData
  ): DiscoveryBasicsInformation => ({
    businessPhoneNumber: businessInfo.personalDetails?.phoneNumber ?? '',
  })
}

import React, { FC, useEffect, useState } from 'react'
import TellUsAboutYourBusinessLayout from '../../layouts/Onboarding5/TellUsAboutYourBusinessLayout'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../layouts/core/PageContainer'
import { useBusinessSignup } from '../../context/useBusinessSignup'
import {
  AboutBusiness,
  BusinessSignupData,
} from '../../types/BusinessSignupData'
import { FormErrorMessage } from '../../layouts/inputs/atoms/form-error-message'
import {
  BusinessBankDetails,
  DiscoveryClassification,
  LoyaltyPercentDetails,
} from '../../models/models'
import { setupDiscoveryService } from '../../services/setupDiscoveryService'
import { DiscoveryCopyData, DiscoveryPhotosData } from '../../models/ApiModels'
import { setupAccountService } from '../../services/setupAccountService'
import Mappers from '../../utils/ApiMappers'

const TellUsAboutYourBusinessScreen: FC = () => {
  const { updateAboutBusiness, businessSignupData } = useBusinessSignup()
  const navigate = useNavigate()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [submitDataError, setSubmitDataError] = useState<string>()

  function onSubmit(aboutBusiness: AboutBusiness) {
    updateAboutBusiness?.(aboutBusiness)
    setIsSaving(true)
    submitData({
      ...businessSignupData,
      aboutBusiness: aboutBusiness,
    }).then((dataSubmitted) => {
      setIsSaving(false)
      if (dataSubmitted) {
        navigate('/onboarding/6')
      }
    })
  }

  /**
   * Submit the data to the server
   * 1. setupAccountService.addBusinessInformation
   * 2. setupDiscoveryService.addBasics
   * 3. setupAccountService.addBankDetails(businessBankDetails)
   * 4. setupAccountService.addLoyaltyPercentage(loyaltyPercentageDetails)
   * 5. setupDiscoveryService.addPhotos(photosData)
   * 6. setupDiscoveryService.addCopy(copyData)
   * 7. setupDiscoveryService.addClassification(updatedClassification)
   * @param businessSignupData
   */
  const submitData = async (
    businessSignupData: BusinessSignupData
  ): Promise<boolean> => {
    try {
      /**
       * 1. Submit business information
       */
      await setupAccountService.addBusinessInformation(
        new Mappers().MapToSetupBusinessInformationRequest(businessSignupData)
      )

      /**
       * 2. Submit the telephone number here.
       */
      await setupDiscoveryService.addBasics(
        new Mappers().MapToBasics(businessSignupData)
      )

      /**
       * 3. Update Bank Details
       * @remarks We don't have bank details but want to make the call to flag to the backend that the registration is complete
       */
      const businessBankDetails: BusinessBankDetails = {
        BankName: businessSignupData?.aboutBusiness?.bankName,
        AccountNumber: businessSignupData?.aboutBusiness?.bankAccountNumber,
        SortCode: businessSignupData?.aboutBusiness?.bankSortCode,
      }
      await setupAccountService.addBankDetails(businessBankDetails)

      /**
       * 4. Update Loyalty Percent
       * @remarks We don't have loyalty percent but want to make the call to flag to the backend that the registration is complete
       */
      const loyaltyPercentageDetails: LoyaltyPercentDetails = {
        LoyaltyPercent: 2,
      }
      await setupAccountService.addLoyaltyPercentage(loyaltyPercentageDetails)

      /**
       * 5. Add photos
       * @remarks We don't have photos but want to make the call to flag to the backend that the registration is complete
       */
      const photosData: DiscoveryPhotosData = {
        nophotos: 'no photos',
      }
      await setupDiscoveryService.addPhotos(photosData)

      /**
       * 6. Add copy
       * @remarks We don't have copy but want to make the call to flag to the backend that the registration is complete
       */
      const copyData: DiscoveryCopyData = {
        TagLine: 'To be completed by xplore copywriter',
        Description: 'To be completed by xplore copywriter',
        Quote: 'To be completed by xplore copywriter',
        QuoteAuthor: 'To be completed by xplore copywriter',
      }
      await setupDiscoveryService.addCopy(copyData)

      /**
       * 7. Add the business classifications
       * @remarks We don't have classifications but want to make the call to flag to the backend that the registration is complete
       */
      const updatedClassification: DiscoveryClassification = {
        PrimaryCategoryId: businessSignupData?.aboutBusiness?.businessCategory!,
        SecondaryCategoryId: '',
        TertiaryCategoryId: '',
        Keywords: ['todo'],
      }
      await setupDiscoveryService.addClassification(updatedClassification)

      return true
    } catch (error) {
      setSubmitDataError(
        "An error has occurred and we couldn't save your data. Please try again."
      )
      console.log('businessSignupData Error: ', error)
    }

    return false
  }

  useEffect(() => {}, [])

  return (
    <PageContainer
      stepTitle="5 / 6"
      width={'w-5/6'}
      title={'Tell us about your independent business'}>
      <div className="">
        <div className="mb-12 mt-4 bg-slate-100 sm:mt-12 sm:p-8">
          <TellUsAboutYourBusinessLayout
            title=""
            onSubmit={onSubmit}
            isSaving={isSaving}
          />
          {submitDataError && (
            <FormErrorMessage className="mt-2 flex justify-center">
              {submitDataError}
            </FormErrorMessage>
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default TellUsAboutYourBusinessScreen

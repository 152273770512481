import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

interface Props {
  title?: string
  isBusy: boolean
  disabled?: boolean
}

const BusySubmitButton: FC<Props> = ({ title, isBusy, disabled = false }) => {
  return (
    <button type="submit" className="btn_default" disabled={isBusy || disabled}>
      {isBusy && (
        <FontAwesomeIcon icon={faSpinner} size="1x" color="text-white" spin />
      )}
      {!isBusy && title}
    </button>
  )
}

export default BusySubmitButton

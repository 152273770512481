import React, { FC } from 'react'
import * as yup from 'yup'
import { boolean, InferType, mixed, object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../inputs/molecules/form-input'
import FormInputCheckbox from '../inputs/molecules/form-input-checkbox'
import FormValidationError from '../core/FormValidationError'
import { BusinessType } from '../../types/BusinessSignupData'
import BusySubmitButton from '../buttons/BusySubmitButton'

interface Props {
  isSaving: boolean
  businessType: BusinessType | undefined
  onSubmit: (
    email: string,
    password: string,
    agreeToGeneralTerms: boolean,
    agreeToIndiesTerms: boolean | undefined
  ) => void
}

interface IFormInputs {
  businessType: BusinessType | undefined
  email: string
  password: string
  confirmPassword: string
  agreeToTAndC: boolean
  agreeToIndependentTAndC: boolean | undefined
}

export const schema = object({
  businessType: mixed<BusinessType>().oneOf([
    'BricksAndMortar',
    'Trader',
    'EventOrganiser',
  ]),
  email: string()
    .required('Email is required')
    .email('You need to enter a valid email address'),
  password: string()
    .required('Password is required')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      'Password must be at least 8 characters with just a mixture of letters and numbers only.'
    ),
  confirmPassword: string()
    .required('Confirm password is required')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  agreeToTAndC: boolean().required(
    'You must agree to the terms and conditions'
  ),
  agreeToIndependentTAndC: boolean().when('businessType', {
    is: 'BricksAndMortar',
    then: boolean().required('You must agree to the terms and conditions'),
  }),
})

export type FormDetails = InferType<typeof schema>

const UserAuthDetailsLayout: FC<Props> = ({
  onSubmit,
  businessType,
  isSaving,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    defaultValues: { businessType },
  })

  const submit = (data: IFormInputs) => {
    onSubmit(
      data.email,
      data.password,
      data.agreeToTAndC,
      data.agreeToIndependentTAndC
    )
  }

  const onGeneralTermsChanged = (value: boolean) =>
    setValue('agreeToTAndC', value)

  const onIndieTermsChanged = (value: boolean) =>
    setValue('agreeToIndependentTAndC', value)

  return (
    <div className="mx-auto p-8 sm:w-2/4">
      <form onSubmit={handleSubmit(submit)}>
        <div className="space-y-7">
          <FormInput<FormDetails>
            id="email"
            type="text"
            name="email"
            label="Email"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="password"
            type="password"
            name="password"
            label="Password"
            description="* Password must be at least 8 characters with just a mixture of letters and numbers only."
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            label="Confirm Password"
            register={register}
            errors={errors}
          />
        </div>
        <div className="mt-8 space-y-4 sm:mt-12">
          <div>
            <FormInputCheckbox
              id="generalTerms"
              name="generalTerms"
              children={
                <label htmlFor="comments" className="text-slate-700">
                  I have read and agree to{' '}
                  <a
                    target="_blank"
                    href="https://app.pixieapp.co/Content/legal/BusinessTermsAndConditions.pdf"
                    className="underline"
                    rel="noreferrer">
                    xplore's General Terms
                  </a>{' '}
                  and the applicable Additional Terms, including the{' '}
                  <a
                    target="_blank"
                    href="https://app.pixieapp.co/privacy-and-terms/privacy-policy"
                    className="underline"
                    rel="noreferrer">
                    Privacy Policy
                  </a>
                </label>
              }
              onChanged={onGeneralTermsChanged}
            />
            <FormValidationError errors={errors} name="agreeToTAndC" />
          </div>
          {businessType === 'BricksAndMortar' && (
            <div>
              <FormInputCheckbox
                id="indieTerms"
                name="indieTerms"
                label="I confirm that I am an independent business with no more than 7 physical locations."
                onChanged={onIndieTermsChanged}
              />
              <FormValidationError
                errors={errors}
                name="agreeToIndependentTAndC"
              />
            </div>
          )}
        </div>

        <div className="mt-12 block flex flex-row justify-center">
          <BusySubmitButton isBusy={isSaving} title="Continue" />
        </div>
      </form>
    </div>
  )
}

export default UserAuthDetailsLayout

import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../layouts/core/PageContainer'
import UserProfileDetailsLayout from '../../layouts/Onboarding3/UserProfileDetailsLayout'
import { useBusinessSignup } from '../../context/useBusinessSignup'
import { Address, PersonalDetails } from '../../types/BusinessSignupData'

interface Props {}

const UserProfileDetailsScreen: FC<Props> = () => {
  const { updateOnBoarding2 } = useBusinessSignup()

  const navigate = useNavigate()

  function onSubmit(
    personalDetails: PersonalDetails,
    homeAddress: Address,
    businessAddress: Address
  ) {
    updateOnBoarding2?.(personalDetails, homeAddress, businessAddress)
    navigate('/onboarding/4')
  }

  return (
    <PageContainer
      stepTitle="3 / 6"
      width={'w-3/6'}
      title={'Verify your identity'}>
      <div className="mx-4 my-2 text-center sm:mx-24 sm:mt-6">
        <h2>
          We only use personal information for identity verification and will
          never share it outside of xplore
        </h2>
      </div>
      <div className="">
        <div className="mb-12 mt-4 bg-slate-100 sm:mt-12 sm:p-8">
          <UserProfileDetailsLayout onSubmit={onSubmit} />
        </div>
      </div>
    </PageContainer>
  )
}

export type { Props as VerifyIdentityPageProps }
export default UserProfileDetailsScreen

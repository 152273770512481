import { useContext } from 'react'
import { BusinessSignupContext } from './BusinessSignupContext'

const useBusinessSignup = () => {
  const {
    businessSignupData,
    setToDefault,
    updateBusinessType,
    updateSubscriptionPlanType,
    updateEmailAndPassword,
    updateOnBoarding2,
    updateAboutBusiness,
  } = useContext(BusinessSignupContext)

  return {
    businessSignupData,
    setToDefault,
    updateBusinessType,
    updateSubscriptionPlanType,
    updateEmailAndPassword,
    updateOnBoarding2,
    updateAboutBusiness,
  }
}

export { useBusinessSignup }

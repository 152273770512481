export type SubscriptionPlanType = 'PixieBasic' | 'PixiePlus'

export type BusinessType = 'BricksAndMortar' | 'Trader' | 'EventOrganiser'

export type LocalStorageKeys =
  | 'businessSignupData'
  | 'businessType'
  | 'userAuthenticationDetails'
  | 'homeAddress'
  | 'businessAddress'
  | 'personalDetails'
  | 'aboutBusiness'

export type BusinessSignupData = {
  businessType: BusinessType | undefined
  subscriptionPlanType: SubscriptionPlanType | undefined
  userAuthenticationDetails: UserAuthenticationDetails | undefined
  homeAddress: Address | undefined
  businessAddress: Address | undefined
  personalDetails: PersonalDetails | undefined
  aboutBusiness: AboutBusiness | undefined
}

/**
 * BusinessSignupDataDefault is the data that is passed to the BusinessSignupContext.
 */
export const BusinessSignupDataDefault: BusinessSignupData = {
  businessType: undefined,
  subscriptionPlanType: 'PixiePlus',
  userAuthenticationDetails: undefined,
  homeAddress: undefined,
  businessAddress: undefined,
  personalDetails: undefined,
  aboutBusiness: undefined,
}

export type UserAuthenticationDetails = {
  email: string
  password: string
  agreeToGeneralTerms: boolean
  agreeToIndiesTerms: boolean | undefined
}

export type PersonalDetails = {
  firstName: string
  lastName: string
  phoneNumber: string
}

export type Address = {
  address1: string
  address2: string | undefined
  city: string
  county: string | undefined
  postcode: string
}

export type AboutBusiness = {
  name: string
  businessType: string | undefined
  businessNumber: string | undefined
  businessCategory: string | undefined
  bankName: string
  bankSortCode: string
  bankAccountNumber?: string
}

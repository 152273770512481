import React, { FC } from 'react'
import clsx from 'clsx'

interface Props {
  id: string
  label: string
  classname?: string
}

const FormLabel: FC<Props> = ({ id, label, classname }) => {
  return (
    <label
      htmlFor={id}
      className={clsx([
        'md:text-normal block text-sm font-medium text-slate-700',
        classname,
      ])}>
      {label}
    </label>
  )
}

export default FormLabel

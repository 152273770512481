import React, { FC } from 'react'
import PageContainer from '../../layouts/core/PageContainer'

interface Props {}

const ScheduleScreen: FC<Props> = (props) => {
  return (
    <PageContainer
      stepTitle="6 / 6"
      width={'w-6/6'}
      title={
        "Congratulations that's the first step to getting your independent business on xplore"
      }>
      <p className="mx-auto mt-12 max-w-2xl px-4 text-center text-lg">
        So what's next ?
      </p>
      <p className="mx-auto my-12 max-w-2xl px-4 text-center text-lg">
        A xplore team member will be in touch to arrange your photo shoot and to
        ask a few questions about the story behind your business for your
        profile.
      </p>
    </PageContainer>
  )
}

export type { Props as ScheduleScreenProps }
export default ScheduleScreen

import React, { FC } from 'react'

const Logo: FC = () => {
  return (
    <div className="flex flex-row items-center">
      <img
        src={'/images/xplore-logo.png'}
        alt="logo"
        className="ml-4 h-12 sm:h-16"
      />
    </div>
  )
}

export default Logo

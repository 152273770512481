import { handleResponse } from '../utils/handleResponse'
import { apiBaseUrl, requestOptions } from '../utils/requestOptions'

export const setupDiscoveryService = {
  getDiscoveryStatus,
  getBasics,
  addBasics,
  addClassification,
  addPhotos,
  addCopy,
  addBioPhoto,
}

async function getDiscoveryStatus() {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/progress`,
    requestOptions.get()
  )

  return handleResponse(response)
}

async function getBasics() {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/basics`,
    requestOptions.get()
  )

  return handleResponse(response)
}

async function addBasics(basics) {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/basics`,
    requestOptions.post(basics)
  )

  return handleResponse(response)
}

async function addClassification(classification) {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/classification`,
    requestOptions.post(classification)
  )

  return handleResponse(response)
}

async function addPhotos(photosData) {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/photos`,
    requestOptions.post(photosData)
  )

  return handleResponse(response)
}

async function addCopy(copy) {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/copy`,
    requestOptions.post(copy)
  )

  return handleResponse(response)
}

async function addBioPhoto(bioPhoto) {
  const response = await fetch(
    `${apiBaseUrl}/merchant/discovery/biophoto`,
    requestOptions.postFileUpload(bioPhoto)
  )

  return handleResponse(response)
}

import { useEffect, useState } from 'react'
import { LocalStorageKeys } from '../types/BusinessSignupData'

function getStorageValue(key: LocalStorageKeys, defaultValue: any) {
  // getting stored value
  const saved: any = localStorage.getItem(key)
  const initial = JSON.parse(saved)
  return initial || defaultValue
}

export const useLocalStorage = (key: LocalStorageKeys, defaultValue: any) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}

import React, { FC } from 'react'
import BusinessTypeCardLayout from './BusinessTypeCardLayout'
import { BusinessType } from '../../types/BusinessSignupData'

interface Props {
  onSubmit: (businessType: BusinessType) => void
}

const BusinessTypeLayout: FC<Props> = (props) => {
  return (
    <div className="mx-4 my-12 grid grid-cols-1 gap-y-4 gap-x-8 sm:grid-cols-3">
      <BusinessTypeCardLayout
        businessType={{
          name: 'Bricks and Mortar',
          description: 'Independent business with less than 7 locations',
        }}
        onSubmit={() => props.onSubmit('BricksAndMortar')}
      />
      <BusinessTypeCardLayout
        businessType={{
          name: 'Market Trader',
          description: 'No fixed location',
        }}
        onSubmit={() => props.onSubmit('Trader')}
      />
      <BusinessTypeCardLayout
        businessType={{
          name: 'Event Organiser',
          description: 'Put your events in front of the xplore community',
        }}
        onSubmit={() => props.onSubmit('EventOrganiser')}
      />
    </div>
  )
}

export type { Props as BusinessTypeLayoutProps }
export default BusinessTypeLayout

import React, { FC } from 'react'
import {
  AboutBusiness,
  Address,
  BusinessSignupDataDefault,
  BusinessType,
  PersonalDetails,
  SubscriptionPlanType,
  UserAuthenticationDetails,
} from '../types/BusinessSignupData'
import { BusinessSignupContext } from './BusinessSignupContext'
import { useLocalStorage } from '../hooks/useLocalStorage'

type BusinessSignupContextProviderProps = {
  children: React.ReactNode
}

const BusinessSignupContextProvider: FC<BusinessSignupContextProviderProps> = ({
  children,
}) => {
  const [businessSignupData, setBusinessSignupData] = useLocalStorage(
    'businessSignupData',
    BusinessSignupDataDefault
  )

  // Set the state back to default.
  const setToDefault = () => {
    const updated = {
      ...businessSignupData,
      ...BusinessSignupDataDefault,
    }
    setBusinessSignupData(updated)
  }

  const updateBusinessType = (businessType: BusinessType) => {
    const updated = {
      ...businessSignupData,
      businessType,
    }
    setBusinessSignupData(updated)
  }

  const updateSubscriptionPlanType = (
    subscriptionPlanType: SubscriptionPlanType
  ) => {
    const updated = {
      ...businessSignupData,
      subscriptionPlanType,
    }
    setBusinessSignupData(updated)
  }

  const updateEmailAndPassword = (
    userAuthenticationDetails: UserAuthenticationDetails
  ) => {
    const updated = {
      ...businessSignupData,
      userAuthenticationDetails,
    }
    setBusinessSignupData(updated)
  }

  const updateOnBoarding2 = (
    personalDetails: PersonalDetails,
    homeAddress: Address,
    businessAddress: Address
  ) => {
    const updated = {
      ...businessSignupData,
      personalDetails: personalDetails,
      homeAddress: homeAddress,
      businessAddress: businessAddress,
    }
    setBusinessSignupData(updated)
  }

  const updateAboutBusiness = (aboutBusiness: AboutBusiness) => {
    const updated = {
      ...businessSignupData,
      aboutBusiness: aboutBusiness,
    }
    setBusinessSignupData(updated)
  }

  return (
    <BusinessSignupContext.Provider
      value={{
        businessSignupData,
        setToDefault,
        updateBusinessType,
        updateSubscriptionPlanType,
        updateEmailAndPassword,
        updateOnBoarding2,
        updateAboutBusiness,
      }}>
      {children}
    </BusinessSignupContext.Provider>
  )
}

export { BusinessSignupContextProvider }

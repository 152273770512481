import React from 'react'
import classNames from 'classnames'
import get from 'lodash.get'

import {
  DeepMap,
  FieldError,
  Path,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import { Input, InputProps } from '../atoms/input'
import { FormErrorMessage } from '../atoms/form-error-message'
import FormLabel from './form-label'
import FormLabelDescription from './form-label-description'

export type FormInputProps<TFormValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
} & Omit<InputProps, 'name'>

const FormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  onChange,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <div className={classNames('', className)} aria-live="polite">
      {props.label && (
        <FormLabel id={props.id} label={props.label} classname="mb-2" />
      )}
      {props.description && <FormLabelDescription text={props.description} />}
      <div className="relative">
        {props.prefixmask && (
          <span className="absolute bottom-2 left-5">{props.prefixmask}</span>
        )}
        <Input
          name={name}
          aria-invalid={hasError}
          className={classNames({
            ' transition-colors hover:border-dark focus:border-dark focus:outline-none focus:ring-1 focus:ring-dark focus:ring-opacity-50':
              hasError,
          })}
          {...props}
          {...(register && register(name, rules))}
        />
        {props.suffixmask && (
          <span className="absolute bottom-2 right-5">{props.suffixmask}</span>
        )}
      </div>
      <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
        )}
      />
    </div>
  )
}

export default FormInput

import React, { FC } from 'react'
import classNames from 'classnames'

export type FormErrorMessageProps = {
  className?: string
  children: any
}

export const FormErrorMessage: FC<FormErrorMessageProps> = ({
  children,
  className,
}) => (
  <p className={classNames('block text-left text-sm text-warning', className)}>
    {children}
  </p>
)

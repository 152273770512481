import React, { FC } from 'react'
import clsx from 'clsx'

interface Props {
  title?: string
  style?: string
  width: string
}

const SignupProgress: FC<Props> = (props) => {
  return (
    <div className="flex flex-col">
      <div className="my-2 font-semibold text-slate-600">{props.title}</div>
      <div
        className={clsx(
          'mb-4 h-4 rounded-full bg-gray-300 sm:h-6',
          props.style
        )}>
        <div
          className={clsx(
            'h-4 rounded-full bg-primary sm:h-6',
            props.width
          )}></div>
      </div>
    </div>
  )
}

export type { Props as SignupProgressProps }
export default SignupProgress

import React, { FC, useState } from 'react'
import { InferType, object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../inputs/molecules/form-input'
import FormInputCheckbox from '../inputs/molecules/form-input-checkbox'
import FormLabel from '../inputs/molecules/form-label'
import { Address, PersonalDetails } from '../../types/BusinessSignupData'

interface Props {
  onSubmit: (
    personalDetails: PersonalDetails,
    homeAddress: Address,
    businessAddress: Address
  ) => void
}

interface IFormInputs {
  firstname: string
  lastname: string
  phoneNumber: string
  homeAddress1: string
  homeAddress2: string | undefined
  homeCity: string
  homeCounty: string | undefined
  homePostcode: string
  businessAddress1: string
  businessAddress2: string | undefined
  businessCity: string
  businessCounty: string | undefined
  businessPostcode: string
}

export const schema = object({
  firstname: string().required('First name is required'),
  lastname: string().required('Last name is required'),
  phoneNumber: string()
    .required('Phone number is required')
    .matches(
      /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
      'Please enter a valid phone number'
    ),
  homeAddress1: string().required('First line of your address is required'),
  homeAddress2: string().notRequired(),
  homeCity: string().required('City is required'),
  homeCounty: string().notRequired(),
  homePostcode: string()
    .required('Postcode is required')
    .matches(
      /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
      'Please enter a valid UK post code.'
    ),
  businessAddress1: string().required('First line of your address is required'),
  businessAddress2: string().notRequired(),
  businessCity: string().required('City is required'),
  businessCounty: string().notRequired(),
  businessPostcode: string()
    .required('Postcode is required')
    .required('Postcode is required')
    .matches(
      /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/,
      'Please enter a valid UK post code.'
    ),
})

export type FormDetails = InferType<typeof schema>

const UserProfileDetailsLayout: FC<Props> = ({ onSubmit }) => {
  const [, setAddressSameAsBusiness] = useState(false)

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  const submit = (data: IFormInputs) => {
    const personalDetails: PersonalDetails = {
      firstName: data.firstname,
      lastName: data.lastname,
      phoneNumber: data.phoneNumber,
    }

    const homeAddress = {
      address1: data.homeAddress1,
      address2: data.homeAddress2,
      city: data.homeCity,
      county: data.homeCounty,
      postcode: data.homePostcode,
    }

    const businessAddress = {
      address1: data.businessAddress1,
      address2: data.businessAddress2,
      city: data.businessCity,
      county: data.businessCounty,
      postcode: data.businessPostcode,
    }

    if (personalDetails && homeAddress && businessAddress) {
      onSubmit(personalDetails, homeAddress, businessAddress)
    }
  }

  const onHandleAddressSameAsBusiness = (value: boolean) => {
    setAddressSameAsBusiness(value)
    if (value) {
      setValue('businessAddress1', getValues().homeAddress1)
      setValue('businessAddress2', getValues().homeAddress2)
      setValue('businessCity', getValues().homeCity)
      setValue('businessCounty', getValues().homeCounty)
      setValue('businessPostcode', getValues().homePostcode)
    }
  }

  return (
    <div className="mx-auto p-8">
      <form onSubmit={handleSubmit(submit)}>
        <div className="space-y-7">
          <FormLabel
            id="personalDetails"
            label="Personal Details"
            classname="font-bold text-lg md:text-xl"
          />

          <FormInput<FormDetails>
            id="firstname"
            type="text"
            name="firstname"
            label="First name"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="lastname"
            type="text"
            name="lastname"
            label="Last name"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            label="Phone number"
            register={register}
            errors={errors}
          />

          <FormLabel
            id="homeAddress"
            label="Home Address"
            classname="font-bold text-lg md:text-xl pt-4 sm:pt-12"
          />

          <FormInput<FormDetails>
            id="homeAddress1"
            type="text"
            name="homeAddress1"
            label="Address line 1"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="homeAddress2"
            type="text"
            name="homeAddress2"
            label="Address line 2"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="homeCity"
            type="text"
            name="homeCity"
            label="City"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="homeCounty"
            type="text"
            name="homeCounty"
            label="County"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="homePostcode"
            type="text"
            name="homePostcode"
            label="Postcode"
            register={register}
            errors={errors}
          />

          <div className="mt-8 space-y-4 sm:mt-12">
            <FormInputCheckbox
              id="addressSameAsBusiness"
              name="addressSameAsBusiness"
              label="My home address and business address are the same"
              onChanged={(value) => onHandleAddressSameAsBusiness(value)}
            />
          </div>

          <>
            <FormLabel
              id="businessAddress"
              label="Business Address"
              classname="font-bold text-lg md:text-xl pt-4 sm:pt-12"
            />

            <FormInput<FormDetails>
              id="businessAddress1"
              type="text"
              name="businessAddress1"
              label="Address line 1"
              register={register}
              errors={errors}
            />

            <FormInput<FormDetails>
              id="businessAddress2"
              type="text"
              name="businessAddress2"
              label="Address line 2"
              register={register}
              errors={errors}
            />

            <FormInput<FormDetails>
              id="businessCity"
              type="text"
              name="businessCity"
              label="City"
              register={register}
              errors={errors}
            />

            <FormInput<FormDetails>
              id="businessCounty"
              type="text"
              name="businessCounty"
              label="County"
              register={register}
              errors={errors}
            />

            <FormInput<FormDetails>
              id="businessPostcode"
              type="text"
              name="businessPostcode"
              label="Postcode"
              register={register}
              errors={errors}
            />
          </>
        </div>

        {/* CTA */}
        <div className="mt-12 block flex flex-row justify-center">
          <button type="submit" className="btn_default">
            Continue
          </button>
        </div>
      </form>
    </div>
  )
}

export default UserProfileDetailsLayout

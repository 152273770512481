import React, { FC } from 'react'

interface Props {
  text: string
}

const FormLabelDescription: FC<Props> = ({ text }) => {
  return (
    <p className="mb-3 -mt-2 text-xs leading-5 text-slate-500  md:leading-6">
      {text}
    </p>
  )
}

export default FormLabelDescription

import { LOCAL_STORAGE_CURRENT_USER_KEY } from './requestOptions'

export function handleResponse(response) {
  console.log('Handle Response', response)

  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      console.log('Non success response code', response.status)
      const error = data || response.statusText

      /**
       * Check if the error is a 400 and we have already submitted in which case we don't want to throw an error for this new merchant signup workflow.
       * @remark: API sends a 400 and response payload in the format {"errorCode":4054,"errorMessage":"This section has already been completed","error":4054,"error_description":"This section has already been completed"}
       */
      if (400 === response.status && data?.errorCode === 4054) {
        console.log('400 4054 error')
        return data
      }

      if ([401, 403].indexOf(response.status) !== -1) {
        // Auto-logout if 401 Unauthorized or 403 Forbidden response returned from api.
        console.log('Redirecting to login!!')
        localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER_KEY)
        //window.location.replace('/')
      }

      return Promise.reject(error)
    }

    return data
  })
}

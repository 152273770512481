import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../layouts/core/PageContainer'
import VerifyIdentityLayout from '../../layouts/Onboarding4/VerifyIdentityLayout'
import { setupAccountService } from '../../services/setupAccountService'
import { AddProofOfIdentityResponse } from '../../models/ApiModels'
import { FormErrorMessage } from '../../layouts/inputs/atoms/form-error-message'

interface Props {
  title?: string
}

const VerifyIdentityScreen: FC<Props> = ({ title = 'VerifyIdentityPage' }) => {
  const [uploadProofError, setUploadProofError] = useState<string>()
  const [isSaving, setIsSaving] = useState(false)

  let navigate = useNavigate()

  const onSubmit = (file: File) => {
    setIsSaving(true)
    uploadFile(file).then((uploaded) => {
      setIsSaving(false)
      if (uploaded) {
        navigate('/onboarding/5')
      }
    })
  }

  const uploadFile = async (fileUpload: File): Promise<boolean> => {
    try {
      const result: AddProofOfIdentityResponse =
        await setupAccountService.addProofOfIdentity(fileUpload)
      return true
    } catch (error) {
      setUploadProofError('An error occurred uploading your proof of identity')
      console.log(error)
    }

    return false
  }

  return (
    <PageContainer
      stepTitle="4 / 6"
      width={'w-4/6'}
      title={'Proof of identity'}>
      <div className="mx-4 mt-2 text-center sm:mx-24 sm:mt-6">
        <h2>
          We require proof of identity in order to create your xplore account.
        </h2>
      </div>
      <div className="">
        <div className="mb-12 mt-4 p-4 sm:mt-12 sm:p-8">
          <VerifyIdentityLayout onSubmit={onSubmit} />
          {uploadProofError && (
            <FormErrorMessage className="flex justify-center">
              {uploadProofError}
            </FormErrorMessage>
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export type { Props as VerifyIdentityPageProps }
export default VerifyIdentityScreen

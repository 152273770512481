import { createContext } from 'react'

import {
  AboutBusiness,
  Address,
  BusinessSignupData,
  BusinessSignupDataDefault,
  BusinessType,
  PersonalDetails,
  SubscriptionPlanType,
  UserAuthenticationDetails,
} from '../types/BusinessSignupData'

type BusinessSignupContextProps = {
  businessSignupData: BusinessSignupData
  setToDefault?: () => void
  updateBusinessType?: (businessType: BusinessType) => void
  updateSubscriptionPlanType?: (
    subscriptionPlanType: SubscriptionPlanType
  ) => void
  updateEmailAndPassword?: (
    userAuthenticationDetails: UserAuthenticationDetails
  ) => void
  updateOnBoarding2?: (
    personalDetails: PersonalDetails,
    homeAddress: Address,
    businessAddress: Address
  ) => void
  updateAboutBusiness?: (aboutBusiness: AboutBusiness) => void
}

const BusinessSignupContext = createContext<BusinessSignupContextProps>({
  businessSignupData: BusinessSignupDataDefault,
})

export { BusinessSignupContext }

import React, { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../layouts/core/PageContainer'
import BusinessTypeLayout from '../../layouts/Onboarding2/BusinessTypeLayout'
import { useBusinessSignup } from '../../context/useBusinessSignup'
import { BusinessType } from '../../types/BusinessSignupData'

interface Props {
  title?: string
}

const BusinessTypeScreen: FC<Props> = (props) => {
  const { setToDefault, updateBusinessType } = useBusinessSignup()

  const navigate = useNavigate()

  /**
   * Set the signup state data to default undefined values.
   */
  useEffect(() => {
    setToDefault?.()
  }, [])

  function onSubmit(businessType: BusinessType) {
    updateBusinessType?.(businessType)
    navigate('/onboarding/3')
  }

  return (
    <PageContainer
      stepTitle="2 / 6"
      width={'w-2/6'}
      title={'What type of business are you ?'}>
      <BusinessTypeLayout onSubmit={onSubmit} />
    </PageContainer>
  )
}

export type { Props as BusinessTypeScreenProps }
export default BusinessTypeScreen

import React from 'react'
import './styles/tailwind.css'
import AppContainer from './layouts/core/AppContainer'
import { Route, Routes } from 'react-router-dom'
import UserAuthDetailsScreen from './screens/Onboarding1/UserAuthDetailsScreen'
import BusinessTypeScreen from './screens/Onboarding2/BusinessTypeScreen'
import VerifyIdentityScreen from './screens/Onboarding4/VerifyIdentityScreen'
import TellUsAboutYourBusinessScreen from './screens/Onboarding5/TellUsAboutYourBusinessScreen'
import ScheduleScreen from './screens/onBoarding6/ScheduleScreen'
import UserProfileDetailsScreen from './screens/Onboarding3/UserProfileDetailsScreen'
import { BusinessSignupContextProvider } from './context/BusinessSignupContextProvider'
import LogRocket from 'logrocket'

function App() {
  LogRocket.init('ikuzne/pixie-signup')
  return (
    <BusinessSignupContextProvider>
      <AppContainer>
        <Routes>
          <Route path="/" element={<UserAuthDetailsScreen />} />
          <Route path="/onboarding/1" element={<UserAuthDetailsScreen />} />
          <Route path="/onboarding/2" element={<BusinessTypeScreen />} />
          <Route path="/onboarding/3" element={<UserProfileDetailsScreen />} />
          <Route path="/onboarding/4" element={<VerifyIdentityScreen />} />
          <Route
            path="/onboarding/5"
            element={<TellUsAboutYourBusinessScreen />}
          />
          <Route path="/onboarding/6" element={<ScheduleScreen />} />
        </Routes>
      </AppContainer>
    </BusinessSignupContextProvider>
  )
}

export default App

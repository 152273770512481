import React, { FC } from 'react'

interface Props {
  title?: string
  children: React.ReactNode
}

const AppContainer: FC<Props> = ({ title = 'PageContainer', children }) => {
  return <div className="mx-auto max-w-7xl">{children}</div>
}

export default AppContainer

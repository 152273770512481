import React, {
  DetailedHTMLProps,
  FC,
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
} from 'react'
import classNames from 'classnames'

export type InputSize = 'medium' | 'large'
export type InputType = 'text' | 'email' | 'password'

export type InputProps = {
  id: string
  name: string
  label: string
  description?: string
  prefixmask?: ReactNode
  suffixmask?: ReactNode
  type?: InputType
  size?: InputSize
  className?: string
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'size'
>

// Using maps so that the full Tailwind classes can be seen for purging
// see https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html

const sizeMap: { [key in InputSize]: string } = {
  medium: 'p-3 text-base',
  large: 'p-4 text-base',
}

// eslint-disable-next-line react/display-name
// @ts-ignore
export const Input: FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      name,
      label,
      type = 'text',
      size = 'medium',
      className = '',
      placeholder,
      ...props
    },
    ref
  ) => (
    <input
      id={id}
      ref={ref}
      name={name}
      type={type}
      aria-label={label}
      placeholder={placeholder}
      className={classNames([
        'block w-full appearance-none rounded-md border border-gray-200 bg-white px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-slate-500 focus:bg-white focus:outline-none focus:ring-slate-500 sm:text-sm',
        sizeMap[size],
        className,
      ])}
      {...props}
    />
  )
)

import React, { FC } from 'react'
import { FormErrorMessage } from '../inputs/atoms/form-error-message'
import { ErrorMessage } from '@hookform/error-message'

interface Props {
  errors: any
  name: string
}

const FormValidationError: FC<Props> = (props) => {
  return (
    <ErrorMessage
      errors={props.errors}
      name={props.name as any}
      render={({ message }) => (
        <FormErrorMessage className="mt-1">{message}</FormErrorMessage>
      )}
    />
  )
}

export type { Props as FormValidationErrorProps }
export default FormValidationError

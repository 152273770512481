import React, { FC } from 'react'

interface Props {
  businessType: {
    name: string
    description: string
  }
  onSubmit: () => void
}

const BusinessTypeCardLayout: FC<Props> = (props) => {
  return (
    <div
      key={props.businessType.name}
      className="divide-y divide-gray-200 rounded-lg border border-gray-200 text-center shadow-sm hover:bg-slate-50">
      <div className="flex h-full flex-col items-center p-6">
        <div className="flex-grow">
          <h2>{props.businessType.name}</h2>
          <p className="mt-4 text-sm text-gray-500">
            {props.businessType.description}
          </p>
        </div>
        <button
          onClick={props.onSubmit}
          className="btn_default flex-grow-0 py-2 sm:mt-6">
          Continue
        </button>
      </div>
    </div>
  )
}

export type { Props as BusinessTypeCardLayoutProps }
export default BusinessTypeCardLayout

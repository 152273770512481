import React, { FC, useState } from 'react'
import { DropDownItem, DropdownListLayout } from '../core/DropdownListLayout'
import { InferType, object, string } from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormInput from '../inputs/molecules/form-input'
import FormValidationError from '../core/FormValidationError'
import { AboutBusiness } from '../../types/BusinessSignupData'
import BusySubmitButton from '../buttons/BusySubmitButton'
import FormLabel from '../inputs/molecules/form-label'

interface Props {
  isSaving: boolean
  title?: string
  onSubmit: (aboutBusiness: AboutBusiness) => void
}

const businessTypes: DropDownItem[] = [
  { id: 'Company', name: 'Company' },
  { id: 'Individual / Sole Trader', name: 'Individual / Sole Trader' },
  { id: 'General Partnership', name: 'General Partnership' },
]

const businessCategories: DropDownItem[] = [
  { id: '1', name: 'Food and drink' },
  { id: '2', name: 'Shop' },
  { id: '3', name: 'Lifestyle' },
  { id: '4', name: 'Things to do' },
  { id: '5', name: 'Sleep' },
  { id: '6', name: 'Services' },
]

interface IFormInputs {
  businessName: string
  businessNumber: string | undefined
  businessType: string
  businessCategory: string
  bankName: string
  bankSortCode: string
  bankAccountNumber: string
}

export const schema = object({
  businessType: string().required('Business type is required'),
  businessCategory: string().required('Business Category is required'),
  businessName: string().required('Business name is required'),
  businessNumber: string().when('businessType', {
    is: 'Company',
    then: string().required('Business number is required'),
  }),
  bankName: string().required('Bank name is required'),
  bankSortCode: string()
    .required('Bank sort code is required')
    .matches(
      /^(\d){2}-(\d){2}-(\d){2}$/,
      'Please enter a valid UK bank sort code XX-XX-XX'
    ),
  bankAccountNumber: string()
    .required('Bank account number is required')
    .matches(/^(\d){8}$/, 'Please enter a valid UK bank account number'),
})

export type FormDetails = InferType<typeof schema>

const TellUsAboutYourBusinessLayout: FC<Props> = (props) => {
  const [businessType, setBusinessType] = useState<string>()
  const [businessCategory, setBusinessCategory] = useState<string>(
    businessTypes[0].id
  )

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  const onBusinessTypeChanged = (businessType: string) => {
    setBusinessType(businessType)
    setValue('businessType', businessType)
  }

  const onBusinessCategoryChanged = (businessCategory: string) => {
    setValue('businessCategory', businessCategory)
  }

  const submit = (data: IFormInputs) => {
    props.onSubmit({
      name: data.businessName,
      businessType: data.businessType,
      businessCategory: data.businessCategory,
      businessNumber: data.businessNumber,
      bankName: data.bankName,
      bankSortCode: data.bankSortCode,
      bankAccountNumber: data.bankAccountNumber,
    })
  }

  return (
    <div className="mx-auto p-8 sm:w-96">
      <form onSubmit={handleSubmit(submit)}>
        <div className="space-y-7">
          <FormInput<FormDetails>
            id="businessName"
            type="text"
            name="businessName"
            label="Business Name"
            register={register}
            errors={errors}
          />

          <div>
            <DropdownListLayout
              items={businessTypes}
              title="Business Type"
              onChange={onBusinessTypeChanged}
            />
            <FormValidationError errors={errors} name="businessType" />
          </div>

          {businessType === 'Company' && (
            <FormInput<FormDetails>
              id="businessNumber"
              type="text"
              name="businessNumber"
              label="Company Registration Number"
              register={register}
              errors={errors}
            />
          )}

          <div>
            <DropdownListLayout
              items={businessCategories}
              title="Business Category"
              onChange={onBusinessCategoryChanged}
            />
            <FormValidationError errors={errors} name="businessCategory" />
          </div>

          <div>
            <FormLabel
              id="bankDetails"
              label="Bank Details"
              classname="font-bold text-lg md:text-xl pt-4 sm:pt-12"
            />
            <FormLabel
              id="bankDetailsReason"
              label="* We need your bank details so we can settle funds to your account."
              classname="text-sm md:text-sm mt-2"
            />
          </div>

          <FormInput<FormDetails>
            id="bankName"
            type="text"
            name="bankName"
            label="Bank name"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="bankSortCode"
            type="text"
            description="Please enter in format XX-XX-XX"
            placeholder="XX-XX-XX"
            name="bankSortCode"
            label="Bank sort code"
            register={register}
            errors={errors}
          />

          <FormInput<FormDetails>
            id="bankAccountNumber"
            type="text"
            name="bankAccountNumber"
            label="Bank account number"
            register={register}
            errors={errors}
          />
        </div>
        <div className="mt-12 block flex flex-row justify-center">
          <BusySubmitButton isBusy={props.isSaving} title="Continue" />
        </div>
      </form>
    </div>
  )
}

export type { Props as TellUsAboutYourBusinessLayoutProps }
export default TellUsAboutYourBusinessLayout

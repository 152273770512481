import React, { FC } from 'react'

interface Props {
  id: string
  name: string
  label?: string
  onChanged: (value: boolean) => void
  children?: React.ReactNode
}

const FormInputCheckbox: FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(false)

  const handleOnChange = () => {
    const updatedValue = !checked
    setChecked(updatedValue)
    props.onChanged(updatedValue)
  }

  return (
    <div className="flex items-start">
      <div className="flex h-5 items-center">
        <input
          onChange={handleOnChange}
          id={props.id}
          aria-describedby="comments-description"
          name={props.name}
          type="checkbox"
          className="h-4 w-4 border-slate-300 text-dark ring-0 focus:ring-0"
        />
      </div>
      <div className="ml-3 text-sm">
        {props.label && (
          <label htmlFor="comments" className="text-slate-700">
            {props.label}
          </label>
        )}
        {props.children && props.children}
      </div>
    </div>
  )
}

export type { Props as FormInputCheckboxProps }
export default FormInputCheckbox

import { apiBaseUrl, requestOptions } from '../utils/requestOptions'
import { handleResponse } from '../utils/handleResponse'

export const userAccountService = {
  createAccount,
  resetPassword,
  getAccountDetails,
}

async function createAccount(email, password, optInToMarketing) {
  console.debug('createAccount account/registerbusinessowner')
  const response = await fetch(
    `${apiBaseUrl}/account/registerbusinessowner`,
    requestOptions.post({
      emailAddress: email,
      password,
      optInToMarketing,
    })
  )

  return handleResponse(response)
}

async function resetPassword(email) {
  const response = await fetch(
    `${apiBaseUrl}/account/forgotpassword`,
    requestOptions.post({
      emailAddress: email,
    })
  )

  return handleResponse(response)
}

async function getAccountDetails() {
  const response = await fetch(
    `${apiBaseUrl}/merchant/useraccount`,
    requestOptions.get()
  )

  return handleResponse(response)
}

import React, { FC } from 'react'
import SignupProgress from '../SignupProgress'
import Logo from './Logo'

interface Props {
  title?: React.ReactNode
  stepTitle?: string
  children?: React.ReactNode
  width: string
}

const PageContainer: FC<Props> = (props) => {
  return (
    <div className="mx-auto my-auto w-full sm:max-w-7xl">
      <div className="mt-12 flex flex-row justify-center sm:mt-24">
        <Logo />
      </div>
      <div className="mx-8 mt-4 sm:mx-4 sm:my-12 sm:mx-8 sm:mt-12">
        <SignupProgress title={props.stepTitle} width={props.width} />
      </div>
      <h1 className="mx-2 mt-8 text-center sm:my-6 lg:mx-52">{props.title}</h1>
      {props.children}
    </div>
  )
}

export default PageContainer
